<template>
  <div 
    class="my-12 text-center"
  >
    <div class="content-container">
      <h1 class="pt-8 pb-4 font-5xl tracking-wide leading-normal font-semibold text-center uppercase">
        Connection you can count on
      </h1>
      <p class="leading-normal pb-8 text-lg">
        Our aim is to help everyday Australians reach their full potential with the high-speed, reliable Internet people deserve!
      </p>
      <div class="flex-wrap flex flex-row md:flex-no-wrap justify-center content-container">
        <div class="w-full text-center px-6">
          <unlimited-data-blue 
            v-if="siteId !== 'qnet'"
            :class="siteId === 'fuz' || siteId === 'hisp' ? 'text-uniti-gold' : 'text-brand-colour-alt'"
            class="m-auto stroke-current" 
          />
          <p class="font-bold uppercase pt-5 pb-2 text-3xl">
            UNLIMITED DATA
          </p>
          <p class="leading-normal mb-5">
            Unlimited data available for Internet plans. Use your data when you want and how you want, no peak or off-peak periods apply.
          </p>
        </div>

        <div class="w-full text-center px-6">
          <phone-bundle 
            v-if="siteId !== 'qnet'"
            :class="siteId === 'fuz' || siteId === 'hisp' ? 'text-uniti-gold' : 'text-brand-colour-alt'"
            class="m-auto stroke-current" 
          />
          <p class="font-bold uppercase pt-5 pb-2 text-3xl">
            <span>ADD-ON</span> PHONE PLANS
          </p>
          <p 
            v-if="siteId === 'fuz' || siteId === 'hisp'"
            class="leading-normal mb-5"
          >
            Add-on a home phone plan to your Uniti internet, all on one account.
          </p>
          <p 
            v-else
            class="leading-normal mb-5"
          >
            Add-on a home phone plan to your {{ brand.name }}<span> internet</span>, all on one account.
          </p>
        </div>

        <div
          class="w-full text-center px-6"
        >
          <no-lock-in 
            v-if="siteId !== 'qnet'"
            :class="siteId === 'fuz' || siteId === 'hisp' ? 'text-uniti-gold' : 'text-brand-colour-alt'"
            class="m-auto stroke-current" 
          />
          <p class="font-bold uppercase pt-5 pb-2 text-3xl">
            NO LOCK IN, NO WORRIES!
          </p>
          <p class="leading-normal mb-5">
            Our flexible contracts allow you to connect on a month-to-month, 13 or 26 month term.
          </p>
        </div>
        <div class="w-full text-center px-6">
          <local-support
            v-if="siteId !== 'qnet'" 
            :class="siteId === 'fuz' || siteId === 'hisp' ? 'text-uniti-gold' : 'text-brand-colour-alt'"
            class="m-auto stroke-current" 
          />
          <p class="font-bold uppercase pt-5 pb-2 text-3xl">
            QUICK SET UP & SUPPORT
          </p>
          <p class="leading-normal mb-5">
            Our friendly customer support team is here to answer all your questions.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UnlimitedDataBlue from '~/components/Icons/UnlimitedDataBlue.vue'
import LocalSupport from '~/components/Icons/LocalSupport.vue'
import NoLockIn from '~/components/Icons/NoLockIn.vue'
import PhoneBundle from '~/components/Icons/PhoneBundle.vue'

export default {
  components: {
    UnlimitedDataBlue,
    LocalSupport,
    NoLockIn,
    PhoneBundle
  },
  data() {
    return {
      siteId: process.env.siteId,
      brand: process.env.brand
    }
  }
}
</script>
